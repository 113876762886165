    .tb-content.tb1 {
  background: url('image/labels/rr1.png') no-repeat, url('image/labels/rr.png') repeat-x;
    }

    .tb-content.tb2 {
        background: url(/src/assets/css/image/labels/rr2.png) no-repeat, url(/src/assets/css/image/labels/rr.png) repeat-x;
    }

    .tb-content.tb3 {
        background: url(/src/assets/css/image/labels/rr3.png) no-repeat, url(/src/assets/css/image/labels/rr.png) repeat-x;
    }

    .tb-content.tb4 {
        background: url(/src/assets/css/image/labels/rr4.png) no-repeat, url(/src/assets/css/image/labels/rr.png) repeat-x;
    }

    .tb-content thead{ margin-bottom: 20px; }
    .tb-content thead tr{padding-bottom: 20px}
    .tb-content thead th{text-align: center; border-bottom: 2px solid #1e2631; color: #1e2631}
    .tb-content tbody td:first-child,
    .tb-content tbody td:nth-child(3),
    .tb-content tbody td:nth-child(5)
    {text-align: center;}
    .tb-content tbody td {
        padding: 5px;
        vertical-align: top;
    }

    .tb-content {
        background-position: top left !important;
        background-size: auto 100px !important;
        padding-top: 90px;
        margin-top: 40px;
        -webkit-box-shadow: -1px 15px 12px 0px rgba(50, 50, 50, 0.52);
      	-moz-box-shadow:    -1px 15px 12px 0px rgba(50, 50, 50, 0.52);
        box-shadow:         -1px 15px 12px 0px rgba(50, 50, 50, 0.52);
    }

    .tb-inner-content {
        background-color: #e6e4e5 !important;
        padding: 0 20px 20px;
    }
